import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import { GitHubIcon} from "../../components/icons";
import { enviaCorreos } from "../../api/correos";
import {toast} from "react-toastify";
import {Alert, Button, Carousel, Col, Image, Row, Container, Card, CardGroup, Badge, Form, FormGroup, Spinner} from "react-bootstrap";

import "./Contacto.scss";
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, Popup} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import "leaflet/dist/images/marker-icon-2x.png";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

function Contacto(props) {

    // Parque rancho banthi 20.3962551,-99.9584373,17.29
    const position1 = [20.3962551, -99.9584373]

    const [formData, setFormData] = useState(initialFormValue());

    const [loading, setLoading] = useState(false);

    const onSubmit = e => {
        e.preventDefault();

        //console.log(formData)
        let cuerpoMensajePropietario = ""
        let cuerpoMensajeCliente = ""

        cuerpoMensajePropietario =
            `<div>
            <h1>Hola</h1>
            <h2>El cliente con correo ${formData.correo} enviado un mensaje</h2>
            <h2>A continuación se muestra que es lo que detallo en su descripción :</h2>
            <br />
            ${formData.mensaje}
            <br />
            <h2>Atentamente https://cleverpack.com.mx/</h2>
            <div/>
            `
        ;

        cuerpoMensajeCliente =
            `<div>
            <h1>Hola ${formData.correo}</h1>
            <h2>A continuación se muestra lo que envio por correo a la administración de CleverPack :</h2>
            <br />
            ${formData.mensaje}
            <br />
            <h2>Atentamente https://cleverpack.com.mx/</h2>
            <div/>
            `
        ;

        // Definicion de envios

        // direccion.comercial@cleverpack.com.mx
        // compras@cleverpack.com.mx

        const dataDireccion = {
            remitente: formData.correo,
            destinatario: "direccion.comercial@cleverpack.com.mx",
            asunto: formData.asunto,
            mensaje: cuerpoMensajePropietario
        }

        const dataCompras = {
            remitente: formData.correo,
            destinatario: "compras@cleverpack.com.mx",
            asunto: formData.asunto,
            mensaje: cuerpoMensajePropietario
        }

        const dataCliente = {
            remitente: "direccion.comercial@cleverpack.com.mx",
            destinatario: formData.correo,
            asunto: `Informacion del correo enviado a Clever pack`,
            mensaje: cuerpoMensajeCliente
        }

        try {
            // Envio de correo a dirección de clever pack
            enviaCorreos(dataDireccion).then(response => {
                const { data } = response;
                toast.success(data.mensaje)
            }).catch(e => {
                console.log(e)
            })

            // Envio de correo a compras de clever pack
            enviaCorreos(dataCompras).then(response => {
                const { data } = response;
                toast.success(data.mensaje)
            }).catch(e => {
                console.log(e)
            })

            // Envio de correo al cliente
            enviaCorreos(dataCliente).then(response => {
                const { data } = response;
                toast.success(data.mensaje)
            }).catch(e => {
                console.log(e)
            })


        } catch (e) {
            console.log(e)
        }

    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    return (
        <>
            <Layout title="Acerca de" description="Acerca de nosotros" >
                <div className="text-center mt-5">
                    <h1>Informacion de contacto</h1>

                    <hr />
                    <Row>
                        <Col>
                            <h1><Badge bg="secondary">Contacto</Badge></h1>

                            <Form onSubmit={onSubmit} onChange={onChange}>

                                <Form.Group as={Col} controlId="formGridNombre">
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control type="text" name="nombre"
                                                  placeholder="Nombre"
                                                  defaultValue={formData.nombre}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCorreo">
                                    <Form.Label>Correo</Form.Label>
                                    <Form.Control type="text" name="correo"
                                                  placeholder="Escribe tu correo"
                                                  defaultValue={formData.correo}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridAsunto">
                                    <Form.Label>Asunto</Form.Label>
                                    <Form.Control type="text" name="asunto"
                                                  placeholder="Escribe el asunto"
                                                  defaultValue={formData.asunto}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridMensaje">
                                    <Form.Label>Mensaje</Form.Label>
                                    <Form.Control as="textarea" rows={3} name="mensaje"
                                                  placeholder="Escribe el mensaje"
                                                  defaultValue={formData.mensaje} />
                                </Form.Group>

                                <Form.Group className="botonSubirProducto">
                                    <Button variant="primary" type="submit">
                                        {!loading ? "Enviar correo" : <Spinner animation="border" />}
                                    </Button>
                                </Form.Group>
                            </Form>
                        </Col>

                        <Col>
                            <h1><Badge bg="secondary">Datos de contacto</Badge></h1>
                            <br></br>
                            <p><b>Rancho Banthi, Querétaro</b></p>
                            <p><b>C.P. 76835</b></p>

                            <CardGroup>
                                <Card>
                                    <Card.Header>
                                        <h4><Badge bg="secondary">Teléfono</Badge></h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>4424755940</p>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <h4><Badge bg="secondary">Correo</Badge></h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>ventas.bajio@ cleverpack.com.mx</p>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header>
                                        <h4><Badge bg="secondary">Horario</Badge></h4>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>Lunes a Viernes de 9:00 a 17:00</p>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                    <hr />
                    
                </div>
            </Layout>
        </>
    );
}

function initialFormValue() {
    return {
        nombre: "",
        correo: "",
        asunto: "",
        mensaje: "",
    }
}

export default Contacto;
