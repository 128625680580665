import React, { useState, useEffect } from 'react';

import ProductItem from './ProductItem';
import styles from './ProductsGrid.module.scss';
import { useProducts } from '../../hooks/useProducts';

import {getTokenApi, isExpiredToken, logoutApi, obtenidusuarioLogueado} from "../../api/auth";
import {toast} from "react-toastify";
import { getProductos, getProductosUsuarios } from "../../api/productos";

const ProductsGrid = () => {

    // Obtener listado de productos para mostrar en la tienda
    // Para guardar los productos
    const [productos, setProductos] = useState(null);

    //Para revisar conexion a internet
    const [conexionInternet, setConexionInternet] = useState(true);

    // Inicia peticion para traer todos los productos
    useEffect(() => {
        try {
            getProductosUsuarios().then(response => {
                const { data } = response;
                //console.log(data);

                if(!productos && data) {
                    setProductos(formatModel(data));
                } else {
                    if(!response) {
                        setConexionInternet(false);
                    } else {
                        const datosUsuarios = formatModel(data)
                        setProductos(datosUsuarios);
                    }
                }
            }).catch((e) => {
                if(e.message == "Request failed with status code 400") {
                    setProductos(null);
                    setConexionInternet(false);
                }
                if(e.message == 'Network Error') {
                    //console.log("No hay internet")
                    //toast.error("Conexión al servidor no disponible");
                    setConexionInternet(false);
                }
            })
        } catch (e) {
        }
    }, []);


    const { products } = useProducts()

    return (
        <div className={styles.p__container}>
            <div className="row">
                <div className="col-sm-8">
                    {
                        productos &&
                            (
                                <>
                                    <div className="py-3">
                                        {productos.length} Productos
                                    </div></>
                            )
                    }
                </div>
            </div>
            {
                productos &&
                    (
                        <>
                            <div className={styles.p__grid}>

                                {
                                    productos.map(product => (
                                        <ProductItem key={product.id} product={product}/>
                                    ))
                                }

                            </div></>
                    )
            }
            <div className={styles.p__footer}>

            </div>
        </div>
     );
}

function formatModel(data) {
    const tempData = []
    data.forEach((producto) => {
        tempData.push({
            id: producto._id,
            name: producto.nombre,
            photo: producto.foto,
            price: parseInt(producto.precio),
            cantidad: producto.cantidad,
            descripcion: producto.descripcion,
            fechaCreacion: producto.createdAt,
            fechaActualizacion: producto.updatedAt
        });
    });
    return tempData;
}

export default ProductsGrid;
