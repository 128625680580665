import React from 'react';
import Layout from '../../components/Layout';

import ProductsGrid from './ProductsGrid';

const Store = () => {

    console.clear();

    return (
        <Layout title="Productos" description="This is the Store page" >
            <div >
                <div className="text-center mt-5">
                    <h1>Productos</h1>
                </div>
                <ProductsGrid/>
            </div>
        </Layout>
     );
}

export default Store;
