import React from 'react';
import Layout from '../components/Layout';
import { GitHubIcon} from '../components/icons'
import {Alert, Button, Carousel, Col, Image, Row, Container, Card, CardGroup, Badge} from "react-bootstrap";

const About = ({ setRefreshCheckLogin }) => {

    return (
        <Layout title="Acerca de" description="Acerca de nosotros" >
            <div className="text-center mt-5">
                <h1><Badge bg="secondary">Acerca de nosotros</Badge></h1>
                <hr />
                <CardGroup>
                    <Card>
                        <Card.Header>
                            <h1><Badge bg="secondary">Cleverpack</Badge></h1>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <b>
                                    Es una empresa mexicana dedicada a la venta de consumibles industriales y de embalaje satisfacemos tus necesidades mediante un amplio stock de productos desarrollando y adaptándonos a tus necesidades.
                                </b>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <h1><Badge bg="secondary">Misión</Badge></h1>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <b>
                                    Satisfacer las necesidades de nuestros clientes mediante el stock y desarrollo de consumibles industriales ofreciendo calidad, precio y tiempos de entrega cortos.
                                </b>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>
                            <h1><Badge bg="secondary">Visión</Badge></h1>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <b>
                                    Ser una empresa líder en soluciones industriales innovando en la industria en beneficio de nuestros socios comerciales.
                                </b>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </div>
        </Layout>
     );
}

export default About;
