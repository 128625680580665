import React, {useState, useEffect, useContext} from 'react';
import "./DashboardCliente.scss";
import { withRouter, useHistory } from "react-router-dom";
import queryString from "query-string";
import LogoAPP from "../../assets/svg/miniatura.svg";
import hamburguer from "../../assets/svg/hamburguer.svg";
import {Alert, Button, Carousel, Col, Image, Row, Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSignInAlt , faEdit, faPlusCircle, faPlus, faTrashAlt, faPen, faCloudUploadAlt, faAddressBook } from "@fortawesome/free-solid-svg-icons";
import BasicModal from "../../components/Modal/BasicModal";
import {getTokenApi, isExpiredToken, logoutApi, obtenidusuarioLogueado} from "../../api/auth";
import {toast} from "react-toastify";
import {getProductos} from "../../api/productos";
import {parseInt} from "lodash";
import {obtenerAdministrador} from "../../api/usuarios";
import { Link } from "react-router-dom";
import {CartIcon} from '../../components/icons';
import styles from '../../components/shared/header.module.scss';
import { CartContext } from '../../contexts/CartContext';
import ListProductos from "../../components/Productos/ListProductos";
import AgregaProductos from "../../components/Productos/AgregaProductos";


function DashboardCliente(props) {
    const { setRefreshCheckLogin, location, history  } = props;
    const [click, setClick] = useState(false);

    const {itemCount} = useContext(CartContext);

    const enrutamiento = useHistory();

    //Para el modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    //Para revisar conexion a internet
    const [conexionInternet, setConexionInternet] = useState(true);

    //Para el final de la pagina
    const [finalPaginacion, setFinalPaginacion] = useState(450);
    //Para la pagina actual
    const [paginaActualPaginacion, setPaginaActualPaginacion] = useState(0);

    // Para guardar los productos
    const [productos, setProductos] = useState(null);
    //Para el estado inicial de la app
    const [estadoInicial, setEstadoInicial] = useState(false);

    //Para validar que se usa el buscador
    const [usoBuscador, setUsoBuscador] = useState(false);

    //Para validar que se usa el buscador
    const [usopaginador, setUsopaginador] = useState(false);

    // Para almecenar el numero de productos registrados hasta el momento
    const [contadorProductos, setContadorProductos] = useState(0);

    //console.clear();

    // Cerrado de sesión automatico
    useEffect(() => {
        if(getTokenApi()) {
            if(isExpiredToken(getTokenApi())) {
                //history.push("")
                //toast.warning("Sesión expirada");
                //toast.success("Sesión cerrada por seguridad");
                logoutApi();
                //setRefreshCheckLogin(true);
            }
        } else {
            enrutamiento.push("/");
        }
    }, []);
    // Termina cerrado de sesión automatico

    // Inicia peticion para traer todos los productos
    useEffect(() => {
        try {
            getProductos(obtenidusuarioLogueado(getTokenApi())).then(response => {
                const { data } = response;
                //console.log(data);

                //console.log("numero de productos "+ data.length)
                setContadorProductos(data.length);

                if(!data && usoBuscador) {
                    //setSinResultados(true);
                }

                if(!data && usopaginador) {
                    //setValidapaginador(true);
                    //setFinalPaginacion(paginaActualPaginacion);
                } else {
                    //setValidapaginador(false);
                }

                if(!data && !usoBuscador && !usopaginador) {
                    setEstadoInicial(true);
                    //setPaginadorActivo(true);
                } else {
                    setEstadoInicial(false);
                    //setPaginadorActivo(false);
                }

                if(!productos && data) {
                    setProductos(formatModel(data));
                } else {
                    if(!response) {
                        setConexionInternet(false);
                    } else {
                        const datosUsuarios = formatModel(data)
                        setProductos(datosUsuarios);
                        //setUsers([users, ...datosUsuarios])
                        //setLoadingUsers(false);
                    }
                }
            }).catch((e) => {
                if(e.message == "Request failed with status code 400") {
                    setProductos(null);
                    setConexionInternet(false);
                    setEstadoInicial(false);
                }
                if(e.message == 'Network Error') {
                    //console.log("No hay internet")
                    //toast.error("Conexión al servidor no disponible");
                    setConexionInternet(false);
                }
            })
        } catch (e) {
        }
    }, [location]);

    //Para guardar la lista de productos
    const [listProductos, setListProductos] = useState(null);

    const handleClick = () => setClick(!click);

    // Cerrado de sesión automatico
    useEffect(() => {
        if(getTokenApi()) {
            if(isExpiredToken(getTokenApi())) {
                //history.push("")
                //toast.warning("Sesión expirada");
                //toast.success("Sesión cerrada por seguridad");
                logoutApi();
                //setRefreshCheckLogin(true);
            }
        }
    }, []);
    // Termina cerrado de sesión automatico

    //Para cerrar la sesion
    const cerrarSesion = () => {
        //console.log("Cerrando sesion");
        history.push("")
        //toast.success("Sesión cerrada");
        /*history.push({
            search: queryString.stringify(""),
        });*/
        logoutApi();
        //setRefreshCheckLogin(true);
    }

    //Para agregar productos
    const agregaProductos = (content) => {
        setTitulosModal("Agrega tu producto");
        setContentModal(content);
        setShowModal(true);
    }

    return (
        <>
            <Container fluid>
                <header className={styles.header}>
                    <Link to='/'>Inicio</Link>
                    <Link to='/Productos'>Productos</Link>
                    <Link to='/acerca-de-nosotros'>Acerca de nosotros</Link>
                    <Link to='/Contacto'>Contacto</Link>
                    <Link to='/dashboard'>Panel de control</Link>
                    <Link to='/Pedidos'> <CartIcon/> Carrito ({itemCount})</Link>
                    <Button onClick={() => cerrarSesion()}>
                        Cerrar Sesion
                        <FontAwesomeIcon icon={faSignInAlt} />
                    </Button>
                </header>

                <div className="agrega">
                    <Row>
                        <Col>
                            <h2>Agrega tus productos</h2>
                        </Col>
                        <Col>
                            <Button
                                className="agregaProductosVacio"
                                onClick={() => {
                                    agregaProductos(<AgregaProductos history={history} setShowModal={setShowModal} />)
                                }
                            }
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Col>
                    </Row>
                </div>

                {
                    productos ?
                        (
                            <ListProductos listDatos={productos} history={history} />
                        )
                        :
                        (
                            <>
                            </>
                        )
                }

            </Container>

            <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                {contentModal}
            </BasicModal>
        </>
    );
}

function useUsersQuery(location) {
    const { page = 1, nombre } = queryString.parse(
        location.search
    );

    return { page, nombre };
}

function formatModel(data) {
    const tempData = []
    data.forEach((producto) => {
        tempData.push({
            id: producto._id,
            nombre: producto.nombre,
            photo: producto.foto,
            precio: producto.precio,
            cantidad: producto.cantidad,
            descripcion: producto.descripcion,
            fechaCreacion: producto.createdAt,
            fechaActualizacion: producto.updatedAt
        });
    });
    return tempData;
}

export default withRouter(DashboardCliente);
