import { API_HOST, API_CLOUDINARY } from "../utils/constants";

import { getTokenApi } from "./auth";
import axios from "axios";

// Guardar productos en cloudinary
export async function subeImagenProducto(imagen) {
    const data = new FormData()
    data.append("file", imagen)
    data.append("upload_preset", "clever-pack")
    data.append("cloud_name","omarlestrella")

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    };

    return await axios.post(API_CLOUDINARY, data, config);
}

/*
const uploadImage = (dataImagen) => {
        const data = new FormData()
        data.append("file", dataImagen)
        data.append("upload_preset", "carrito-compras")
        data.append("cloud_name","omarlestrella")
        fetch("https://api.cloudinary.com/v1_1/omarlestrella/image/upload",{
            method:"post",
            body: data
        })
            .then(resp => console.log(resp))
            .then(data => {
                console.log(data)
            })
            .catch(err => console.log(err))
    }
 */
