import React from 'react';
import Layout from '../../components/Layout';
import { GitHubIcon} from '../../components/icons'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import slider1 from "../../assets/jpg/cinta";
import slider2 from "../../assets/jpg/playo";
import slider3 from "../../assets/jpg/tela";

import { Image, Badge } from "react-bootstrap";

import "./Inicio.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Pagination, Navigation } from "swiper";

function Inicio(props) {
    const { setRefreshCheckLogin } = props;

    return (
        <>
            <Layout title="Inicio" description="Acerca de nosotros" >
                <Swiper
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[ Pagination ]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <Image
                            src={slider3}
                            width="100%"
                        />
                        <div className="textSlider">
                            <h1>Clever pack</h1>
                            <p>Venta de Consumibles</p>
                            <p>Industriales y Embalaje</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image
                            src={slider2}
                            width="100%"
                        />

                        <div className="slider2">
                            <h1>Productos</h1>
                            <p>Gran variedad en productos </p>
                            <p>consumibles industriales y de Embalaje con los </p>
                            <p>mejores precios y productos de alta calidad.</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Image
                            src={slider1}
                            width="100%"
                        />
                    </SwiperSlide>
                </Swiper>
            </Layout>
        </>
    );
}

export default Inicio;

