import React, { useState, useEffect } from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";

import "./EliminacionFisicaProductos.scss";
import {modificarProductos, eliminaProducto} from "../../../api/productos";
import {toast} from "react-toastify";
import queryString from "query-string";

function EliminacionFisicaProductos(props) {
    const { listData, setShowModal, setRefreshCheckLogin, history } = props;
    const { id, nombre } = listData;

    //console.log(dataDepto)

    // Para almacenar los datos recibidos
    const [formData, setFormData] = useState(initialFormValue(listData));

    // Para determinar la animacion de carga
    const [loading, setLoading] = useState(false);

    const onSubmit = e => {
        e.preventDefault();

        //console.log(formData);
        setLoading(true);

        try {
            eliminaProducto(id).then(response => {
                const { data } = response;

                toast.success(data.status);
                setShowModal(false);
                setLoading(false);
                history.push({
                    search: queryString.stringify(""),
                });
            })
        } catch (e) {
            console.log(e)
        }
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Form onSubmit={onSubmit} onChange={onChange}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridNombre">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text"
                                      name="nombre"
                                      disabled={true}
                                      defaultValue={formData.nombre}
                        />
                    </Form.Group>
                </Row>

                <Form.Group className="btnEliminar">
                    <Button variant="primary" type="submit">
                        {!loading ? "¿Desea eliminar el producto?" : <Spinner animation="border" />}
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
}

function initialFormValue(data) {
    const { nombre, precio, descripcion, cantidad } = data;
    //console.log(nombre)

    return {
        nombre: nombre,
        precio: precio,
        descripcion: descripcion,
        cantidad: cantidad
    }
}

export default EliminacionFisicaProductos;
