import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Store from '../pages/store';
import About from '../pages/About';
import NotFound from '../pages/NotFound';
import Cart from "../pages/cart";
import DashboardCliente from "../pages/DashboardCliente";
import Inicio from "../pages/Inicio";
import Contacto from "../pages/Contacto";

const Routes = () => {
  return (
    <Router>
        <Switch>
          <Route path="/Contacto" component={Contacto}/>
          <Route path="/acerca-de-nosotros" component={About} />
          <Route path="/Productos" component={Store}/>
          <Route path="/Pedidos" component={Cart} />
          <Route path="/dashboard" component={DashboardCliente} />
          <Route exact path="/" component={Inicio}/>
          <Route path="*" component={NotFound} />
        </Switch>
    </Router>
  );
}

export default Routes;
