import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistroProductos,
    ENDPOINTVerProducto,
    ENDPOINTModificaProducto,
    ENDPOINTEliminaProducto,
    ENDPOINTListaProductos,
    ENDPOINTObtenerImagenProducto,
    ENDPOINTListaProductosAdministrador,
    ENDPOINTContarProductos,
    ENDPOINTRestarInventario
} from "./endpoints";

import { getTokenApi } from "./auth";
import axios from "axios";

// Obten listado de productos del admnistrador
export async function getProductosAdministrador() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.get(API_HOST + ENDPOINTListaProductosAdministrador, config);
}

// Obten listado de productos de la tienda
export async function getProductos(id) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.get(API_HOST + ENDPOINTListaProductos + `/${id}`, config);
}

export async function getProductosUsuarios() {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.get(API_HOST + ENDPOINTListaProductos, config);
}

// Obten el conteo de productos de una tienda
export async function conteoProductos(id) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.get(API_HOST + ENDPOINTContarProductos + `/${id}`, config);
}

// Obtener producto
export async function datosProducto(id) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTVerProducto + `?id=${id}`, config);
}

// Elimina producto
export async function eliminaProducto(id) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminaProducto + `/${id}`, config);
}

// Obten imagen del producto
export async function getImagenProducto(nombre){

    const config = {
        responseType: 'blob',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerImagenProducto + `/${nombre}`, config);
}


// Nueva funcion de registro de productos
export async function subirProductos(imagenProducto, multiplesArchivos, data){
    //console.log(data)
    const { nombre, precio, idCliente, descripcion, caracteristicas } = data;
    //console.log(imagenProducto)
    //console.log(multiplesArchivos[0])
    //console.log(multiplesArchivos.length)
    const numeroImagenes = multiplesArchivos.length


    const formData = new FormData();
    formData.append("producto", imagenProducto);
    formData.append("nombre", nombre);
    formData.append("precio", precio);
    formData.append("descripcion", descripcion);
    formData.append("idCliente", idCliente);
    formData.append("caracteristicas", caracteristicas);
    for(let i = 0; i < numeroImagenes; i+=1) {
        formData.append("producto", multiplesArchivos[i]);
    }

    //console.log(formData)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistroProductos, formData, config);
}

// Nueva funcion de modificado de productos
export async function modificarProductos(imagenProducto, arrayGaleria, data, id){
    //console.log(data)
    const { nombre, precio, idCliente, descripcion, caracteristicas } = data;
    //console.log(imagenProducto)

    let valida;

    const formData = new FormData();
    if(arrayGaleria) {
        valida = true;
        const numeroImagenes = arrayGaleria.length
        if(imagenProducto) {
            formData.append("producto", imagenProducto);
            formData.append("nombre", nombre);
            formData.append("precio", precio);
            formData.append("descripcion", descripcion);
            formData.append("idCliente", idCliente);
            formData.append("caracteristicas", caracteristicas);
            formData.append("datos", "ProductoYGaleria")
            for(let i = 0; i < numeroImagenes; i+=1) {
                formData.append("producto", arrayGaleria[i]);
            }
        } else {
            formData.append("nombre", nombre);
            formData.append("precio", precio);
            formData.append("descripcion", descripcion);
            formData.append("idCliente", idCliente);
            formData.append("caracteristicas", caracteristicas);
            formData.append("datos", "Galeria")
            for(let i = 0; i < numeroImagenes; i+=1) {
                formData.append("producto", arrayGaleria[i]);
            }
        }



    } else {
        valida = true;
        //const formData = new FormData();
        formData.append("producto", imagenProducto);
        formData.append("nombre", nombre);
        formData.append("precio", precio);
        formData.append("descripcion", descripcion);
        formData.append("idCliente", idCliente);
        formData.append("caracteristicas", caracteristicas);
        formData.append("datos", "Producto")
    }

    if(!imagenProducto && !valida) {
        formData.append("nombre", nombre);
        formData.append("precio", precio);
        formData.append("descripcion", descripcion);
        formData.append("idCliente", idCliente);
        formData.append("caracteristicas", caracteristicas);
        formData.append("datos", "Normal")
    }

    //console.log(formData)
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTModificaProducto + `/${id}`, formData, config);
}

// Funciones

// Registrar producto
export async function registraProductos(id, data) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistroProductos, data, config);
}

// Modificar producto
export async function actualizarProductos(id, data) {
    console.log("la data ", data)
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTModificaProducto + `/${id}`, data, config);
}

// Modificar la cantidad del producto en inventario
export async function actualizarInventario(id, data) {
    const dataTemp = {
        cantidad: data.toString()
    }
    console.log(dataTemp)
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    return await axios.put(API_HOST + ENDPOINTRestarInventario + `/${id}`, dataTemp, config);
}
