import React, {useState, useEffect} from 'react';
import {ToastContainer} from "react-toastify";
import {AuthContext} from "./utils/contexts";
import {isUserLogedApi} from "./api/auth";
import DashboardCliente from "./pages/DashboardCliente";
import { HelmetProvider } from 'react-helmet-async';
import ProductsContextProvider from './contexts/ProductsContext';
import CartContextProvider from './contexts/CartContext';
import Routes from './routes';

function App(props) {
    const [user, setUser] = useState(null);
    const [LoadUser, setLoadUser] = useState(false);
    const [refreshCheckLogin, setRefreshCheckLogin] = useState(false);

    //console.clear();

    useEffect(() => {
        setUser(isUserLogedApi());
        setRefreshCheckLogin(false);
        setLoadUser(true);
    }, [refreshCheckLogin]);

    if(!LoadUser) return null;

    return(
        <AuthContext.Provider value={user}>
            {user ? (
                    <>
                        <HelmetProvider>
                            <ProductsContextProvider>
                                <CartContextProvider>
                                    <Routes setRefreshCheckLogin={setRefreshCheckLogin} />
                                </CartContextProvider>
                            </ProductsContextProvider>
                        </HelmetProvider>
                    </>
                )
                :
                (
                    <>
                        <HelmetProvider>
                            <ProductsContextProvider>
                                <CartContextProvider>
                                    <Routes setRefreshCheckLogin={setRefreshCheckLogin} />
                                </CartContextProvider>
                            </ProductsContextProvider>
                        </HelmetProvider>
                    </>
                )
            }

            <ToastContainer
                position="top-right"
                autoClose={5000}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        </AuthContext.Provider>);
}

export default App;
