import { API_HOST } from "../utils/constants";
import {
    ENDPOINTRegistraUsuarios, ENDPOINTListarUsuarios,
    ENDPOINTObtenerUsuario, ENDPOINTEliminarUsuario,
    ENDPOINTActualizarUsuario, ENDPOINTDeshabilitaUsuario
} from "./endpoints";
import axios from 'axios';
import {getTokenApi, obtenidusuarioLogueado} from "./auth";
export let nombreUsuario = "";
export let apellidosUsuario = "";

// Recupera nombre del usuario
if(getTokenApi()) {
    try {
        obtenerUsuario(obtenidusuarioLogueado(getTokenApi())).then(response =>
        {
            //console.log(response);
            const { data: { nombre, apellidos } } = response;
            nombreUsuario = nombre;
            apellidosUsuario = apellidos;
            return nombre, apellidos;
        })
    } catch (e) {
        //
    }
}

// Registra usuarios
export async function registraUsuarios(data, fotoPerfil) {
    const { nombre, apellidos, correo, password, estadoUsuario, curp, nss, rfc, telefonoCelular, calle, numeroExterior, colonia, municipio, estado, pais, departamento } = data;

    //console.log(data)
    //console.log(fotoPerfil)
    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("apellidos", apellidos);
    formData.append("correo", correo);
    formData.append("password", password);
    formData.append("estadoUsuario", estadoUsuario);
    formData.append("curp", curp);
    formData.append("nss", nss);
    formData.append("rfc", rfc);
    formData.append("telefonoCelular", telefonoCelular);
    formData.append("calle", calle);
    formData.append("numeroExterior", numeroExterior);
    formData.append("colonia", colonia);
    formData.append("municipio", municipio);
    formData.append("estado", estado);
    formData.append("pais", pais);
    formData.append("departamento", departamento);
    if(fotoPerfil) {
        formData.append("fotoPerfil", fotoPerfil);
    }

    //console.log(formData)

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTRegistraUsuarios, formData, config);
}

// Para obtener todos los datos del usuario
export async function obtenerUsuario(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTObtenerUsuario + `/${params}`, config);
}

// Para listar todos los usuarios
export async function listarUsuarios(params) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };
    return await axios.get(API_HOST + ENDPOINTListarUsuarios, config);
}

// Elimina cliente fisicamente de la bd
export async function eliminaUsuario(id) {
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.delete(API_HOST + ENDPOINTEliminarUsuario + `/${id}`, config);
}

// Deshabilita el usuario
export async function deshabilitaUsuario(id, data) {
    //console.log(data)
    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTDeshabilitaUsuario + `/${id}`, data, config);
}

// Modifica datos del usuario
export async function actualizaUsuario(id, data, fotoPerfil) {
    const { nombre, apellidos, correo, password, estadoUsuario, curp, nss, rfc, telefonoCelular, calle, numeroExterior, colonia, municipio, estado, pais, departamento } = data;

    //console.log(data)
    //console.log(fotoPerfil)
    const formData = new FormData();
    formData.append("nombre", nombre);
    formData.append("apellidos", apellidos);
    formData.append("correo", correo);
    formData.append("password", password);
    formData.append("estadoUsuario", estadoUsuario);
    formData.append("curp", curp);
    formData.append("nss", nss);
    formData.append("rfc", rfc);
    formData.append("telefonoCelular", telefonoCelular);
    formData.append("calle", calle);
    formData.append("numeroExterior", numeroExterior);
    formData.append("colonia", colonia);
    formData.append("municipio", municipio);
    formData.append("estado", estado);
    formData.append("pais", pais);
    formData.append("departamento", departamento);
    if(fotoPerfil) {
        formData.append("fotoPerfil", fotoPerfil);
    }

    //console.log(formData)

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.put(API_HOST + ENDPOINTActualizarUsuario + `/${id}`, formData, config);
}
