import React, { useState, useEffect } from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";

import "./Detalles.scss";
import {modificarProductos, eliminaProducto} from "../../../api/productos";
import {toast} from "react-toastify";
import queryString from "query-string";
import imagenNoDisponible from "../../../assets/png/imagen-no-disponible.png";

function Detalles(props) {
    const { listData, setShowModal, setRefreshCheckLogin, history } = props;
    const { id, nombre, descripcion, photo } = listData;

    //console.log(listData)

    // Para almacenar los datos recibidos
    const [formData, setFormData] = useState(initialFormValue(listData));

    // Para determinar la animacion de carga
    const [loading, setLoading] = useState(false);

    const onSubmit = e => {
        e.preventDefault();

        //console.log(formData);
        setLoading(true);

        try {
            eliminaProducto(id).then(response => {
                const { data } = response;

                toast.success(data.status);
                setShowModal(false);
                setLoading(false);
                history.push({
                    search: queryString.stringify(""),
                });
            })
        } catch (e) {
            console.log(e)
        }
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Form onSubmit={onSubmit} onChange={onChange}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridNombre">
                        <Row>
                            <Col>
                                <h3>{nombre}</h3>
                            </Col>
                            <Col>
                                {
                                    photo ?
                                        (
                                            <>
                                                <img
                                                    alt={nombre}
                                                    style={{margin: "-56 auto", maxHeight: "145px"}}
                                                    src={photo} className="img-fluid d-block"/>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <img
                                                    alt={nombre}
                                                    style={{margin: "0 auto", maxHeight: "50px"}}
                                                    src={imagenNoDisponible} className="img-fluid d-block"/>
                                            </>
                                        )
                                }
                            </Col>
                        </Row>
                        <hr />
                        <br/>
                        <h4>{formData.descripcion}</h4>
                    </Form.Group>
                </Row>
            </Form>
        </>
    );
}

function initialFormValue(data) {
    const { nombre, precio, descripcion, cantidad } = data;
    //console.log(nombre)

    return {
        nombre: nombre,
        precio: precio,
        descripcion: descripcion,
        cantidad: cantidad
    }
}

export default Detalles;
