import React, { createContext, useReducer } from 'react';
import { CartReducer, sumItems } from './CartReducer';
import { enviaCorreos } from "../api/correos";
import { actualizarInventario } from "../api/productos";
import {toast} from "react-toastify";

export const CartContext = createContext()

const storage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
const initialState = { cartItems: storage, ...sumItems(storage), checkout: false };

const CartContextProvider = ({children}) => {

    const [state, dispatch] = useReducer(CartReducer, initialState)

    const increase = payload => {
        dispatch({type: 'INCREASE', payload})
    }

    const decrease = payload => {
        dispatch({type: 'DECREASE', payload})
    }

    const addProduct = payload => {
        dispatch({type: 'ADD_ITEM', payload})
    }

    const removeProduct = payload => {
        dispatch({type: 'REMOVE_ITEM', payload})
    }

    const clearCart = () => {
        dispatch({type: 'CLEAR'})
    }

    const handleCheckout = (correoCliente) => {
        //console.log('Datos de la compra', state);
        //console.log("El correo del cliente ", correoCliente);
        const { itemCount, total, cartItems } = state;
        //console.log(cartItems.id)
        // id, cantidad y el numero solicitado esta en quantity
        const contadorProductos = cartItems.length;

        //console.log(cartItems)
        //console.log(cartItems[0].price)

        // Descontar de inventario los productos
        for(let d = 0; d < contadorProductos; d+=1) {
            try {
                actualizarInventario(cartItems[d].id, cartItems[d].quantity).then(response => {
                    const { data }  = response;

                    // console.log(data);
                }).catch(e => {
                    console.log(e)
                })
            } catch (e) {
                console.log(e)
            }
        }


        const listadoDetalles = []
        for(let i = 0; i < contadorProductos; i+=1) {
            listadoDetalles.push(
                `
                <h3><b>Nombre del producto : </b> ${cartItems[i].name} </h3>
                <h3><b>Precio : </b> ${cartItems[i].price} </h3>
                <h3><b>Cantidad : </b> ${cartItems[i].quantity} </h3>
                <div/>
            `);
        }

        //console.log(listadoDetalles);

        // Envio de correos
        let cuerpoMensajePropietario = ""
        let cuerpoMensajeCliente = ""

        cuerpoMensajePropietario =
            `<div>
            <h1>Hola</h1>
            <h2>El cliente con correo ${correoCliente} a registrado un nuevo pedido</h2>
            <h2>A continuación se listan los productos que se añadieron al carrito :</h2>
            ${listadoDetalles}
            <h2><b>Numero de productos solicitados : </b>  ${itemCount} </h2>
            <h2><b>Total a pagar $ </b> ${total} MXN </h2>
            <br />
            <br />
            <h2>Atentamente Página web</h2>
            <div/>
            `
        ;

        cuerpoMensajeCliente =
            `<div>
            <h1>Hola ${correoCliente}</h1>
            <h2>A continuación se listan los productos que añadió al carrito :</h2>
            ${listadoDetalles}
            <h2><b>Numero de productos solicitados : </b>  ${itemCount} </h2>
            <h2><b>Total a pagar $ </b> ${total} MXN </h2>
            <br />
            <h3>El vendedor lo atenderá en breve</h3>
            <br />
            <div/>
            `
        ;

        //gerencia@ideasysolucionestecnologicas.com

        const dataPropietario = {
            remitente: "omar.lopez.estrella.tec@gmail.com",
            destinatario: "l16590486@sjuanrio.tecnm.mx",
            asunto: `Solicitud de pedido`,
            mensaje: cuerpoMensajePropietario
        }

        const dataCliente = {
            remitente: "omar.lopez.estrella.tec@gmail.com",
            destinatario: correoCliente,
            asunto: `Informacion de su pedido`,
            mensaje: cuerpoMensajeCliente
        }

        try {
            enviaCorreos(dataPropietario).then(response => {
                const { data } = response;
                //console.log(data)
                toast.success(data.mensaje)
            }).catch(e => {
                console.log(e)
            })

            enviaCorreos(dataCliente).then(response => {
                const { data } = response;
                //console.log(data)
                toast.success(data.mensaje)
            }).catch(e => {
                console.log(e)
            })
        } catch (e) {
            console.log(e)
        }

        dispatch({type: 'CHECKOUT'})
    }

    const contextValues = {
        removeProduct,
        addProduct,
        increase,
        decrease,
        clearCart,
        handleCheckout,
        ...state
    }

    return (
        <CartContext.Provider value={contextValues} >
            { children }
        </CartContext.Provider>
     );
}

export default CartContextProvider;
