import React, { useState } from 'react';
import {Container, Row, Col, Button, Card, Image, Form, Spinner} from "react-bootstrap";
import { Link, Redirect, useHistory } from "react-router-dom";
import {values, size} from "lodash";
import {toast} from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faMapMarked, faUserPlus} from "@fortawesome/free-solid-svg-icons";
import "./Login.scss";
import {isEmailValid} from "../../utils/validations";
import {login, setTokenApi} from "../../api/auth";
import jwtDecode from "jwt-decode";
import {obtenerUsuario} from "../../api/usuarios";

export default function Login(props) {
    const { setRefreshCheckLogin, setShowModal } = props;
    const [formData, setFormData] = useState(initialFormValue());
    const [signInLoading, setSignInLoading] = useState(false);
    //Para mostrar la contraseña
    const [mostrarPassword, setMostrarPassword] = useState(false);
    const togglePasswordVisiblity = () => {
        setMostrarPassword(mostrarPassword ? false : true);
    };

    const history = useHistory();

    const onSubmit = async e => {
        e.preventDefault();

        let validCount = 0;
        values(formData).some(value => {
            value && validCount++;
            return null;
        });

        //console.log(formData);

        if (validCount != size(formData)) {
            toast.warning("Completa todos los campos del formulario.")
        } else {
            if (!isEmailValid(formData.email)) {
                toast.warning("Correo no valido")
            }  else {
                setSignInLoading(true);
                //console.log("En construccion");
                try {
                    login(formData).then(response => {
                        //console.log(response.status)
                        //console.log(response)
                        const { data: { token } } = response;
                        setTokenApi(token);
                        // setRefreshCheckLogin(true); //en pausa, si recarga hace login
                        const { _ } = jwtDecode(token);
                        const idUdsuario = _;
                        try {
                            obtenerUsuario(idUdsuario).then(response =>
                            {
                                //console.log(response);
                                const { data: { nombre, apellidos } } = response;
                                //console.log("Bienvenido "+nombre+" "+apellidos);
                                setShowModal(false);
                                //setRefreshCheckLogin(true);
                                history.push('/dashboard');
                                toast.success("Bienvenido "+nombre+" "+apellidos);
                            }).catch(e => {
                                console.log(e)
                            })
                        } catch (e) {
                            //
                        }
                    }).catch((e) => {
                        //console.log(e.response)
                        if(e.message == 'Network Error') {
                            //console.log("No hay internet")
                            toast.error("Conexión al servidor no disponible");
                            setSignInLoading(false);
                        } else {
                            if(e.response && e.response.status === 401) {
                                const { mensaje } = e.response.data;
                                toast.error(mensaje);
                                setSignInLoading(false);
                            }
                        }
                    })

                } catch (e) {
                    //toast con error
                    //console.log(e.message)
                }

            }
        }
    };

    const onChange = e => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    return (
        <>
            <div className="sign-in-form">
                <Form onSubmit={onSubmit} onChange={onChange}>
                    <Col className="campoCorreo">
                        <Form.Group controlId="formGridEmail">
                            <Form.Label>Correo Electronico</Form.Label>
                            <Form.Control type="email" name="email"
                                          placeholder=""
                                          defaultValue={formData.email} />
                        </Form.Group>
                    </Col>

                    <Col className="campoPassword">
                        <Form.Group controlId="formGridPassword">
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control name="password"
                                          type={mostrarPassword ? "text" : "password"}
                                          placeholder=""
                                          defaultValue={formData.password} />
                            <FontAwesomeIcon
                                icon={faEye}
                                onClick={togglePasswordVisiblity}
                            />
                        </Form.Group>
                    </Col>

                    <Form.Group className="botonLogin">
                        <Button variant="primary" type="submit">
                            {!signInLoading ? "Iniciar Sesión" : <Spinner animation="border" />}
                        </Button>
                    </Form.Group>

                </Form>
            </div>
        </>
    );
}

function initialFormValue() {
    return{
        email: "",
        password: "",
    }
}

