import { API_HOST } from "../utils/constants";
import {
    ENDPOINTEnviaCorreoPersonalizado
} from "./endpoints";

import { getTokenApi } from "./auth";
import axios from "axios";

// Registrar producto
export async function enviaCorreos(data) {
    //console.log(data);

    const config = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenApi()}`
        }
    };

    return await axios.post(API_HOST + ENDPOINTEnviaCorreoPersonalizado, data, config);
}

