import React, { useState, useEffect, useCallback } from 'react';
import "./AgregaProductos.scss";
import { Button, Col, Form, Row, Spinner, FormControl} from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import {map, size, values} from "lodash";
import {registraProductos, subirProductos} from "../../../api/productos";
import queryString from "query-string";
import {getTokenApi, obtenidusuarioLogueado} from "../../../api/auth";
import Dropzone from "../../../components/Dropzone";
import { subeImagenProducto } from "../../../api/cloudinary";

export default  function AgregaProductos(props) {
    const { setShowModal, setRefreshCheckLogin, location, history } = props;
    const [formData, setFormData] = useState(initialFormValue());
    const [loading, setLoading] = useState(false);

    //Para almacenar la imagen del producto que se guardara a la bd
    const [imagenProducto, setImagenProducto] = useState(null);

    //Para almecenar los multiples archivos
    const [multiplesArchivos, setMultiplesArchivos] = useState(null);

    const onSubmit = e => {
        e.preventDefault();

        let url_foto = ""

        //console.log(imagenProducto);
        //console.log(formData)
        //uploadImage(imagenProducto)

        try {
            subeImagenProducto(imagenProducto).then(response => {
                const { data } = response;
                //console.log(data.secure_url);
                url_foto = data.secure_url;

                const dataTemp = {
                    ...formData,
                    foto: data.secure_url
                }

                let validCount = 1;
                values(formData).some(value => {
                    value && validCount++;
                    return null;
                });

                if (size(formData) !== validCount) {
                    toast.warning("Completa el formulario");
                } else {
                    setLoading(true);
                    try {
                        registraProductos(getTokenApi(),dataTemp).then(response =>
                        {
                            const { data } = response;
                            if (response.data.code) {
                                toast.warning(response.data.message);
                            } else {
                                history.push({
                                    search: queryString.stringify(""),
                                });
                                toast.success(data.status);
                                setShowModal(false);
                                setFormData(initialFormValue());
                            }
                        })
                    } catch (e) {

                    }
                }//
            })
        } catch (e) {

        }
    }

    function handleFile(e) {
        // Getting the files from the input
        let files = e.target.files;
        setMultiplesArchivos({ files });
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Form onSubmit={onSubmit} onChange={onChange}>
                <div className="imagenPrincipal">
                    <h4 className="textoImagenPrincipal">Sube la foto del producto</h4>
                    <div className="imagenProducto">
                        <Dropzone
                            setImagenFile={setImagenProducto}
                        />
                    </div>
                </div>

                <div className="datosDelProducto">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridNombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" name="nombre"
                                          placeholder="Nombre del producto"
                                          defaultValue={formData.nombre}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPrecio">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control type="number" name="precio"
                                          placeholder="Precio del producto"
                                          defaultValue={formData.precio}
                                          min="0"
                                          step="0.01"
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridCantidad">
                            <Form.Label>Cantidad</Form.Label>
                            <Form.Control type="number" name="cantidad"
                                          placeholder="Cantidad"
                                          defaultValue={formData.cantidad}
                                          min="0"
                            />
                        </Form.Group>
                    </Row>

                    <Form.Group as={Col} controlId="formGridCaracteristicas">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} name="descripcion"
                                      placeholder="Escribe la descripcion"
                                      defaultValue={formData.descripcion} />
                    </Form.Group>
                </div>

                <Form.Group className="botonSubirProducto">
                    <Button variant="primary" type="submit">
                        {!loading ? "Agrega el producto" : <Spinner animation="border" />}
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
}

function initialFormValue() {
    return {
        nombre: "",
        precio: "",
        descripcion: "",
        cantidad: "",
    }
}
