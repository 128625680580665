import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import "./Dropzone.scss";

export default function Dropzone(props) {
    const { setImagenFile, imagenProductoBD } = props;

    const [slide, setSlide] = useState([]);

    const onDropImagen = useCallback((acceptedFiles) => {
        //console.log(acceptedFiles);

        setSlide(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        const file = acceptedFiles[0];
        //setURLFinal(URL.createObjectURL(file));
        setImagenFile(file);
    });

    const {getRootProps, getInputProps} = useDropzone({
        accept: "image/*",
        onDrop: onDropImagen,
    });

    useEffect(() => {
        slide.map((file, key) => {
            const tempType = file.type.split("/");
            const type = tempType[0];
            const temp = file.name.split(".");
            const Ext = temp[1];
            //console.log(Ext);
            //console.log(type);
            if(type !== "image") {
                //const tempP = file.preview;
                //slider1(tempP);
                toast.error("Archivo no permitido");
            }
        });
    }, [slide]);

    const visualizarSlide1 = slide.map(file => (
        <div key={file.name}>
            <div>
                <img
                    src={file.preview}
                />
            </div>
        </div>
    ));

    return (
        <div className="archivo"
             {...getRootProps()}
        >
            {imagenProductoBD && slide.length === 0 ?
                (
                    <>
                        <aside>
                            <img
                                src={imagenProductoBD}
                            />
                        </aside>
                    </>
                )
                :
                slide.length !== 0 &&
                    (
                        <>
                            <aside>
                                {visualizarSlide1}
                            </aside>
                        </>
                    )
            }
            <input {...getInputProps()} />
        </div>
    );
}
