import React, { useState, useEffect } from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {size, values} from "lodash";
import {toast} from "react-toastify";
import {actualizarProductos, registraDepartamento} from "../../../api/productos";
import queryString from "query-string";
import Dropzone from "../../../components/Dropzone";
import { subeImagenProducto } from "../../../api/cloudinary";

import"./ModificacionProductos.scss";

function ModificacionProductos(props) {
    const { listData, setShowModal, history } = props;
    const { id, nombre, photo } = listData;

    //console.log(photo)

    //console.log(listData)

    // Para almacenar datos del departamento
    const [formData, setFormData] = useState(initialFormValue(listData));

    //Para almacenar la imagen del producto que se guardara a la bd
    const [imagenProducto, setImagenProducto] = useState(null);

    // Para la animacion de carga
    const [loading, setLoading] = useState(false);

    const onSubmit = e => {
        e.preventDefault();

        let url_foto = ""

        //console.log(imagenProducto)
        //console.log(formData)

        //console.log(formData.precio.type)

        if(imagenProducto) {
            //console.log("entra con imagen")
            try {
                subeImagenProducto(imagenProducto).then(response => {
                    const { data } = response;
                    console.log(data.secure_url);
                    url_foto = data.secure_url;

                    const dataTemp = {
                        ...formData,
                        foto: data.secure_url
                    }

                    let validCount = 1;
                    values(formData).some(value => {
                        value && validCount++;
                        return null;
                    });

                    if(size(formData) !== validCount) {
                        //toast.warning("Completa el formulario")
                    } else {
                        setLoading(true);

                        try {
                            actualizarProductos(id, dataTemp).then(response => {
                                const { data } = response;

                                toast.success(data.status);
                                setShowModal(false);
                                setLoading(false);
                                history.push({
                                    search: queryString.stringify(""),
                                });
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                })
            } catch (e) {

            }
        } else {
            //console.log("entra sin imagen")
            let validCount = 1;
            values(formData).some(value => {
                value && validCount++;
                return null;
            });

            if(size(formData) !== validCount) {
                //toast.warning("Completa el formulario")
            } else {
                setLoading(true);

                try {
                    actualizarProductos(id, formData).then(response => {
                        const { data } = response;

                        toast.success(data.status);
                        setShowModal(false);
                        setLoading(false);
                        history.push({
                            search: queryString.stringify(""),
                        });
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    return (
        <>
            <Form onSubmit={onSubmit} onChange={onChange}>
                <div className="imagenPrincipal">
                    <h4 className="textoImagenPrincipal">Sube la foto del producto</h4>
                    <div className="imagenProducto">
                        <Dropzone
                            setImagenFile={setImagenProducto} imagenProductoBD={photo}
                        />
                    </div>
                </div>

                <div className="datosDelProducto">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridNombre">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control type="text" name="nombre"
                                          placeholder="Nombre del producto"
                                          defaultValue={formData.nombre}
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPrecio">
                            <Form.Label>Precio</Form.Label>
                            <Form.Control type="number" name="precio"
                                          placeholder="Precio del producto"
                                          defaultValue={formData.precio}
                                          min="0"
                                          step="0.01"
                            />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridCantidad">
                            <Form.Label>Cantidad</Form.Label>
                            <Form.Control type="number" name="cantidad"
                                          placeholder="Cantidad"
                                          defaultValue={formData.cantidad}
                                          min="0"
                            />
                        </Form.Group>
                    </Row>

                    <Form.Group as={Col} controlId="formGridCaracteristicas">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control as="textarea" rows={3} name="descripcion"
                                      placeholder="Escribe la descripcion"
                                      defaultValue={formData.descripcion} />
                    </Form.Group>
                </div>

                <Form.Group className="btnEliminar">
                    <Button variant="primary" type="submit">
                        {!loading ? "¿Desea modificar los datos?" : <Spinner animation="border" />}
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
}

function initialFormValue(data) {
    const { nombre, precio, descripcion, cantidad } = data;
    //console.log(nombre)

    return {
        nombre: nombre,
        precio: precio,
        descripcion: descripcion,
        cantidad: cantidad
    }
}

export default ModificacionProductos;
