import React, { createContext, useState, useEffect } from 'react';
import { dummyProducts } from '../services/dummy';
export const ProductsContext = createContext()

const ProductsContextProvider = ({children}) => {

    // Inicia proceso para traer de la bd los productos
    // Termina proceso para traer de la bd los productos

    const [products] = useState(dummyProducts);

    return (
        <ProductsContext.Provider value={{products}} >
            { children }
        </ProductsContext.Provider>
     );
}

export default ProductsContextProvider;
