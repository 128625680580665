import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { CartContext } from '../../contexts/CartContext';
import {CartIcon} from '../icons';
import styles from './header.module.scss';
import {toast} from "react-toastify";
import BasicModal from "../../components/Modal/BasicModal";
import { Button, Col, Container, Image, Row, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faQrcode, faNewspaper, faPhoneFlip, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Login from "../Login";
import {getTokenApi, isExpiredToken, logoutApi, obtenidusuarioLogueado} from "../../api/auth";
import LogoCleverPack from "../../assets/png/clever-pack.png";
import LogoFacebook from "../../assets/png/logo-facebook.png";

const Header = () => {

    // Para hacer uso del modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    const enrutamiento = useHistory();

    // Para controlar la vista del boton
    const [determinaInicioSesion, setDeterminaInicioSesion] = useState(false);

    const {itemCount} = useContext(CartContext);

    //Para abir modal de inicio de sesion
    const openModalInicioSesion = (content) => {
        setTitulosModal("Inicio de sesión");
        setContentModal(content);
        setShowModal(true);
    }

    // Cerrado de sesión automatico
    useEffect(() => {
        if(getTokenApi()) {
            if(isExpiredToken(getTokenApi())) {
                //history.push("")
                toast.warning("Sesión expirada");
                toast.success("Sesión cerrada por seguridad");
                logoutApi();
                //setRefreshCheckLogin(true);
            }
        } else {
            setDeterminaInicioSesion(false);
        }
    }, []);
    // Termina cerrado de sesión automatico

    useEffect(() => {
        if(getTokenApi()) {

            setDeterminaInicioSesion(true);
        } else {
            setDeterminaInicioSesion(false);
        }
    }, [determinaInicioSesion]);


    //Para cerrar la sesion
    const cerrarSesion = () => {
        //console.log("Cerrando sesion");
        //history.push("")
        toast.success("Sesión cerrada");
        /*history.push({
            search: queryString.stringify(""),
        });*/
        logoutApi();
        setDeterminaInicioSesion(false);
        //setRefreshCheckLogin(true);
    }

    // Para abrir pagina de facebook
    const abrirfacebook = () => {
        window.open("https://www.facebook.com/Cleverpack-373962436536020/", "_blank");
    }


    return (
        <>
            <div className="datosPrincipales">
                <Container classname="container-fluid contenido" style={{ fontSize: "14px"}}>
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faPhoneFlip} />
                            Telefono 4424755940
                        </Col>
                        <Col>
                            <FontAwesomeIcon icon={faEnvelope} /> direccion.comercial@cleverpack.com.mx
                        </Col>
                        <Col>
                            <Image
                                src={LogoFacebook} width="20px"
                                style={{cursor: "pointer"}}
                                onClick={() => {
                                    abrirfacebook()
                                }}
                            />
                            Facebook
                        </Col>
                    </Row>
                </Container>
            </div>
            <Navbar collapseOnSelect expand="lg" className={styles.header}>
                <Container>
                    <Navbar.Brand> <Image src={LogoCleverPack} width="100px" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to='/'>Inicio</Link>
                            <Link to='/Productos'>Productos</Link>
                            <Link to='/Pedidos'> <CartIcon/> Carrito ({itemCount})</Link>
                            <Link to='/acerca-de-nosotros'>Acerca de nosotros</Link>
                            <Link to='/Contacto'>Contacto</Link>
                            {
                                determinaInicioSesion &&
                                (
                                    <>
                                        <Link to='/dashboard'>Panel de control</Link>
                                    </>
                                )
                            }
                            <Navbar.Collapse className="justify-content-end">
                                {
                                    determinaInicioSesion ?
                                        (
                                            <>
                                                <Button onClick={() => cerrarSesion()}>
                                                    Cerrar Sesion
                                                    <FontAwesomeIcon icon={faSignInAlt} />
                                                </Button>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Button
                                                    onClick={() => openModalInicioSesion(<Login setShowModal={setShowModal} />)}
                                                >
                                                    Inicia Sesion
                                                    <FontAwesomeIcon icon={faSignInAlt} />
                                                </Button>
                                            </>
                                        )
                                }
                            </Navbar.Collapse>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <header className={styles.header}>
            </header>

            <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                {contentModal}
            </BasicModal>
        </>
     );
}

export default Header;
