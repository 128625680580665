import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import imagenNoDisponible from "../../assets/png/imagen-no-disponible.png";
import BasicModal from "../../components/Modal/BasicModal";
import { useCart } from '../../hooks/useCart';
import { formatNumber } from '../../helpers/utils';
import Detalles from "../../components/Productos/Detalles";

const ProductItem = ({product}) => {

    //console.log(product)

    // Para hacer uso del modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    // Detalles del producto
    const detallesProducto = (content) => {
        setTitulosModal("Informacion del producto");
        setContentModal(content);
        setShowModal(true);
    }

    const { addProduct, cartItems, increase } = useCart();

    const isInCart = product => {
        return !!cartItems.find(item => item.id === product.id);
    }

    return (
        <>
            <div className="card card-body">
                {
                    product.photo ?
                        (
                            <>
                                <img style={{display: "block", margin: "0 auto 10px", maxHeight: "200px"}} className="img-fluid"
                                     src={product.photo + '?v=' + product.id} alt=""/>
                            </>
                        )
                        :
                        (
                            <>
                                <img style={{display: "block", margin: "0 auto 10px", maxHeight: "200px"}} className="img-fluid"
                                     src={imagenNoDisponible} alt=""/>
                            </>
                        )
                }
                <p>{product.name}</p>
                <h3 className="text-left">{formatNumber(product.price)} MXN</h3>
                <div className="text-right">
                    <Link
                        className="btn btn-link btn-sm mr-2"
                        onClick={() => {
                            const data = {
                                id: product.id,
                                nombre: product.name,
                                descripcion: product.descripcion,
                                photo: product.photo
                            }

                            detallesProducto(<Detalles listData={data} setShowModal={setShowModal} />)
                        }}
                    >
                        Detalles
                    </Link>

                    {
                        product.cantidad === "0" ?
                            (
                                <>
                                    <button
                                        className="btn btn-outline-primary btn-sm">Producto no disponible</button>
                                </>
                            )
                            :
                            (
                                <>
                                    {
                                        isInCart(product) &&
                                        <button
                                            onClick={() => increase(product)}
                                            className="btn btn-outline-primary btn-sm">Agregar más</button>
                                    }

                                    {
                                        !isInCart(product) &&
                                        <button
                                            onClick={() => addProduct(product)}
                                            className="btn btn-primary btn-sm">Añadir al carrito</button>
                                    }
                                </>
                            )
                    }

                </div>
            </div>

            <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                {contentModal}
            </BasicModal>
        </>
     );
}

export default ProductItem;
