import React, { useState, useEffect, useMemo } from 'react';
import moment from "moment";
import 'moment/locale/es';
import { useHistory } from "react-router-dom";
import styled from 'styled-components';
import {Button, Container} from "react-bootstrap";

import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import Detalles from "../Detalles";

import "./ListProductos.scss";
import {map} from "lodash";
import {Badge, Table} from "react-bootstrap";
import BasicModal from "../../../components/Modal/BasicModal";
import EliminacionFisicaProductos from "../EliminacionFisica";
import ModificacionProductos from "../Modificacion";
import Footer from "../../../components/shared/footer";

function ListProductos(props) {
    const { listDatos, setRefreshCheckLogin, history } = props;

    //console.log(listDepartamentos)
    moment.locale("es");

    //console.log(listDatos)

    const enrutamiento = useHistory();

    // Para ir hacia la ruta de los departamentos
    const regresarRuta = () => {
        enrutamiento.push("/Departamentos");
    }

    // Para hacer uso del modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    //Para la eliminacion fisica de clientes
    const eliminaProductos = (content) => {
        setTitulosModal("Eliminando");
        setContentModal(content);
        setShowModal(true);
    }

    // Para la modificacion de datos
    const modificaProducto = (content) => {
        setTitulosModal("Modificando");
        setContentModal(content);
        setShowModal(true);
    }

    // Definicion de tabla
    const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

    const columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
            right: true,
            reorder: true
        },
        {
            name: 'Precio',
            selector: row => "$ " + row.precio + " MXN",
            sortable: true,
            right: true,
            reorder: true
        },
        {
            name: 'Cantidad',
            selector: row => row.cantidad,
            sortable: true,
            right: true,
            reorder: true
        },
        {
            name: 'Descripcion',
            selector: row => row.descripcion,
            sortable: true,
            right: true,
            reorder: true
        },
        {
            name: 'Última modificación',
            selector: row => moment(row.fechaActualizacion).calendar(),
            sortable: true,
            right: true,
            reorder: true
        },
        {
            name: 'Acciones',
            right: true,
            reorder: true,
            selector: row => (
                <>
                    <Badge
                        bg="success"
                        className="editar"
                        onClick={() => {
                            const data = {
                                id: row.id,
                                nombre: row.nombre,
                                photo: row.photo,
                                precio: row.precio,
                                cantidad: row.cantidad,
                                descripcion: row.descripcion
                            }
                            modificaProducto(<ModificacionProductos listData={data} history={history} setShowModal={setShowModal} />)
                        }}
                    >
                        Editar
                    </Badge>
                    <Badge
                        bg="danger"
                        className="eliminar"
                        onClick={() => {
                            const data = {
                                id: row.id,
                                nombre: row.nombre,
                                precio: row.precio,
                                cantidad: row.cantidad,
                                descripcion: row.descripcion
                            }
                            eliminaProductos(<EliminacionFisicaProductos listData={data} history={history} setShowModal={setShowModal} />);
                        }}
                    >
                        Eliminar
                    </Badge>
                </>
            )
        }
    ];

    // Configurando animacion de carga
    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);


    useEffect(() => {
        const timeout = setTimeout(() => {
            setRows(listDatos);
            setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos'
    };

    // Procesa documento para descargar en csv
    function convertArrayOfObjectsToCSV(array) {
        let result;
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredItems[0]);
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
        array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });
        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
        const filename = 'Datos.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Descargar CSV</Button>;

    const descargaCSV = useMemo(() => <Export onExport={() => downloadCSV(filteredItems)} />, []);

    const [filterText, setFilterText] = useState("");
    const [resetPaginationToogle, setResetPaginationToogle] = useState(false);



    // Defino barra de busqueda
    const ClearButton = styled(Button) ` 
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

    const TextField = styled.input ` 
        height: 32px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;
      &:hover {
        cursor: pointer;
      }
    `;


    const filteredItems = listDatos.filter(
        item => item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase())
    );

    const  subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToogle(!resetPaginationToogle);
                setFilterText('');
            }
        };

        return (
            <>
                <TextField
                    id="search"
                    type="text"
                    placeholder="Busqueda por nombre"
                    aria-label="Search Input"
                    value={filterText}
                    onChange={e => setFilterText(e.target.value)}
                />
                <ClearButton type="button" onClick={handleClear}>
                    X
                </ClearButton>
            </>
        );
    }, [filterText, resetPaginationToogle]);

    return (
        <>
            <Container>
                <DataTable
                    title="Lista de productos"
                    columns={columns}
                    data={filteredItems}
                    actions={descargaCSV}
                    progressPending={pending}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    paginationResetDefaultPage={resetPaginationToogle}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                />
            </Container>

            <BasicModal show={showModal} setShow={setShowModal} title={titulosModal}>
                {contentModal}
            </BasicModal>

            <Footer/>
        </>
    );
}

export default ListProductos;
