import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import CartProducts from './CartProducts';
import Layout from '../../components/Layout';
import { useCart } from '../../hooks/useCart';
import { formatNumber } from '../../helpers/utils';
import { Button, Col, Form, Row, Spinner, FormControl} from "react-bootstrap";
import { toast } from "react-toastify";

const Cart = () => {

    const { total, cartItems, itemCount, clearCart, checkout, handleCheckout } = useCart();

    // Para hacer uso del modal
    const [showModal, setShowModal] = useState(false);
    const [contentModal, setContentModal] = useState(null);
    const [titulosModal, setTitulosModal] = useState(null);

    // Para controlar si se debe mostrar el cuadro para escribir el correo
    const [muestraCampoCorreo, setMuestraCampoCorreo] = useState(false);

    // Para el modal de confirmar pedido
    const confirmaPedido = () => {
        setMuestraCampoCorreo(true);
        //handleCheckout
        const recuperaCorreo = document.getElementById("correo").value;
        if(!recuperaCorreo) {
            toast.warning("Debe escribir su correo");
        } else {
            handleCheckout(recuperaCorreo)
        }
    }


    return (
        <>
            <Layout title="Cart" description="This is the Cart page" >
                <div >
                    <div className="text-center mt-5">
                        <h1>Carrito de compras</h1>

                    </div>

                    <div className="row no-gutters justify-content-center">
                        <div className="col-sm-9 p-3">
                            {
                                cartItems.length > 0 ?
                                    <CartProducts/> :
                                    <div className="p-3 text-center text-muted">
                                        No hay productos en el carrito
                                    </div>
                            }

                            { checkout &&
                                <div className="p-3 text-center text-success">
                                    <p>Compra completada</p>
                                    <Link to="/" className="btn btn-outline-success btn-sm">Regresa al catálogo de productos</Link>
                                </div>
                            }
                        </div>
                        {
                            cartItems.length > 0 &&
                            <div className="col-sm-3 p-3">
                                <div className="card card-body">
                                    <p className="mb-1">Numero de productos</p>
                                    <h4 className=" mb-3 txt-right">{itemCount}</h4>
                                    <p className="mb-1">Total</p>
                                    <h3 className="m-0 txt-right">{formatNumber(total)}</h3>
                                    {
                                        muestraCampoCorreo &&
                                            (
                                                <>
                                                    <hr className="my-4"/>
                                                    <Form.Group as={Col} controlId="formGridNombre">
                                                        <Form.Label>Escribe tu correo</Form.Label>
                                                        <Form.Control type="text" name="correo" id="correo"
                                                                      placeholder="Correo"

                                                        />
                                                    </Form.Group>
                                                </>
                                            )
                                    }

                                    <hr className="my-4"/>
                                    <div className="text-center">
                                        <button type="button" className="btn btn-primary mb-2" onClick={() => confirmaPedido()}>Proceder a comprar</button>
                                        <button type="button" className="btn btn-outlineprimary btn-sm" onClick={clearCart}>Liberar carrito</button>
                                    </div>

                                </div>
                            </div>
                        }

                    </div>
                </div>
            </Layout>
        </>
     );
}

export default Cart;
