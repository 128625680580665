// Rutas de la API

// Login
export const ENDPOINTLoginAdministrador = "/login";

// Usuarios
export const ENDPOINTRegistraUsuarios = "/administradores/registro";
export const ENDPOINTListarUsuarios = "/administradores/listar";
export const ENDPOINTObtenerUsuario = "/administradores/obtenerUsuario";
export const ENDPOINTEliminarUsuario = "/administradores/eliminar";
export const ENDPOINTActualizarUsuario ="/administradores/actualizar";
export const ENDPOINTDeshabilitaUsuario = "/administradores/deshabilitar";

// Productos
export const ENDPOINTRegistroProductos = "/productos/registro";
export const ENDPOINTVerProducto = "/productos/ver";
export const ENDPOINTEliminaProducto = "/productos/eliminar";
export const ENDPOINTListaProductosAdministrador = "/productos/listar";
export const ENDPOINTObtenerImagenProducto = "/productos/fotoProducto";

export const ENDPOINTListaProductos = "/productos/listar";
export const ENDPOINTContarProductos = "/productos/contarProductos";
export const ENDPOINTModificaProducto = "/productos/actualizar";

export const ENDPOINTRestarInventario = "/productos/actualizarCantidad";

// Envio de correos

// Envia al propietario del sistema
export const ENDPOINTEnviaCorreo = "/correos/enviar";

// Envia correo con datos del carrito al comprador
export const ENDPOINTEnviaCorreoPersonalizado = "/correos/enviarCorreo";
